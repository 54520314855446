import { Fragment, useEffect, useState } from "react";
import { ILeagueHistoryData, ITeamData } from "../controller_responses/LeagueHistoryData";
import { StatsLink, TeamLinkROMHelmet } from "../helpers/SiteLinks";
import { Link } from "react-router-dom";

export function LeagueHistory() {
    const [leagueHistoryData, setLeagueHistoryData] = useState<ILeagueHistoryData>();

    let teamDataList: ITeamData[] | undefined;
    if (leagueHistoryData) { 
        ({ teamDataList } = leagueHistoryData);
    }
    

    useEffect(()=>{
        document.title = "Tecmo Championship Series - League History";  //TODO: Figure out how to just add the "League History" piece from this 'page'
        fetch(`${process.env.PUBLIC_URL}/LeagueHistoryData`)
            .then(response=>{return response.json()})
            .then((data: ILeagueHistoryData)=> setLeagueHistoryData(data) );
    },[]);

    return (
        <>
            <div className="DetailBox">
                <h3>Awards</h3>
                <ul>
                    <li><Link to="/AllAmericans">All Americans</Link></li>
                    <li><Link to="/MunozWinners">Anthony Muñoz Award</Link></li>
                    <li><Link to="/HartLeeDykesWinners">Hart Lee Dykes Award</Link></li>
                </ul>
            </div>

            <div className="DetailBox">
                <h3>Statistics</h3>
                <ul>
                    <li><Link to="/StatisticsRecordBook">TCS Record Book</Link></li>
                    <li><StatsLink>Historical League Stats</StatsLink></li>
                </ul>
            </div>

            <div className="DetailBox">
                <h3>Standings</h3>
                <ul>
                    <li><Link to="/HistoricalStandings">Historical League Standings</Link></li>
                    <li><Link to="/HistoricalRankings">Historical League Rankings</Link></li>
                    <li><Link to="/ConferenceHistory">Conference Standings</Link></li>
                    <li><Link to="/BowlResults">Bowl Results</Link></li>
                    {/*
                    <li>Bowl Standings</li>
                    <li>Bowl Prestige</li>
                    <li>Bowl Summary By Season</li>
                    */}
                </ul>
            </div>

            <div className="DetailBox">
                <h3>Miscellanea</h3>
                <ul>
                    {/*
                    <li>Rivalries?</li>
                    <li>Retired Jerseys</li>
                    <li>Hall of Fame (Eventually)</li>
                    <li>Banner Explanation (Sois Idea)</li>
                    */}
                    <li><a href='bowl-history.php'>Bowl History</a></li>
                    {/*<li>League History?</li>*/}
                </ul>
            </div>

            <div style={{clear: "both"}}>
                <hr/>
                <center><h3>TCS Team Archive</h3></center>
                {(!teamDataList) ? null : teamDataList.map((teamData) => 
                    <Fragment key={teamData.id}>
                        <TeamLinkROMHelmet teamData={teamData} />
                    </Fragment>
                )}
            </div>
        </>
    );
}