import { Fragment, useEffect, useState } from "react";
import { IAllAmericanPlayerData, IAllAmericanTeamData, IAllAmericansData } from "../controller_responses/AllAmericansData";
import { ImageTypeDirectory } from "../helpers/ImageLocations";
import { Link, useParams } from "react-router-dom";
import { PlayerNameLink, TeamLinkHelmetProject } from "../helpers/SiteLinks";

export function AllAmericans(){
    const [allAmericansData, setAllAmericansData] = useState<IAllAmericansData>();

    const { Season } = useParams();

    useEffect(()=>{
        document.title = "Tecmo Championship Series - All Americans";  //TODO: Figure out how to just add the "All Americans" piece from this 'page'
        const allAmericansDataRequestBody = new URLSearchParams();        
        allAmericansDataRequestBody.append('season', Season??"");
        fetch(`${process.env.PUBLIC_URL}/AllAmericanData`, {
            body: allAmericansDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: IAllAmericansData)=> setAllAmericansData(data) );
    },[Season, setAllAmericansData]);

    let seasonsList = [];
    if (allAmericansData?.currentSeason){
        for (let seasonNumber = 1; seasonNumber <= allAmericansData?.currentSeason; seasonNumber++){
            seasonsList.push(seasonNumber);
        }
    }

    return (
        <>
        <center><h1>All Americans</h1></center>
	    <center><img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.awards}${allAmericansData?.awardImageLarge}`} alt="" /></center>
	    <p>The Best of the Best in the TCS are here!  They are immortalized as All-Americans here in the TCS Trophy Room! Click on a season to see the winners!</p>
        <center>
            <u style={{fontSize: 'large'}}>Seasons</u><br/>
            <b style={{fontSize: 'x-large'}}><Link to="" style={{fontSize: 'x-large'}}>Summary</Link></b>
            {seasonsList.map((seasonNumber)=>
            <Fragment key={seasonNumber}>
                <b style={{fontSize: 'x-large'}}> - </b><Link to={`./${seasonNumber}`} style={{fontSize: 'x-large'}}>{seasonNumber}</Link>
            </Fragment>
            )}
            {Season ? 
            <AllAmericanPlayersSummary allAmericanPlayerDataList={allAmericansData?.allAmericanPlayerDataList}/> :
            <AllAmericanTeamSummary allAmericanTeamDataList={allAmericansData?.allAmericanTeamDataList}/>
            }
        </center>
        </>
    );
}

interface IAllAmericanTeamSummary {
    allAmericanTeamDataList: IAllAmericanTeamData[] | undefined
}
function AllAmericanTeamSummary(props: IAllAmericanTeamSummary){
    const { allAmericanTeamDataList } = props;
    return (
        <>
            <br/><br/><u style={{fontSize: '14px'}}><b>Selections By School</b></u><br/>
	        <table width={'75%'} style={{background: '#ccc'}}>
                <tbody>
                    {allAmericanTeamDataList?.map((allAmericanTeamData)=>
                    <>
                    <tr style={{textAlign: 'center', background: '#fff', width: '50%'}}>
                        <td width={'50%'}><TeamLinkHelmetProject teamData={allAmericanTeamData} /></td>
                        <td><span style={{fontSize: 'xx-large'}}>{allAmericanTeamData.numberOfWinners}</span></td>
                    </tr>
                    </>
                    )}
                </tbody>
            </table>
        </>
    );
}

interface IAllAmericanPlayersSummary {
    allAmericanPlayerDataList: IAllAmericanPlayerData[] | undefined
}
function AllAmericanPlayersSummary(props: IAllAmericanPlayersSummary){
    const { allAmericanPlayerDataList } = props;
    return (
        <>
            <br/><br/>
            <center>
            <table width={'75%'} style={{background: '#ccc'}}>
                <tbody>
                {allAmericanPlayerDataList?.map((allAmericanPlayerData)=>
                <Fragment key={allAmericanPlayerData.playerData.id}>
                    <tr style={{textAlign: 'center', background: '#fff', width: '50%'}}>
                        <td width={'33%'}><TeamLinkHelmetProject teamData={allAmericanPlayerData.teamData} /></td>
                        <td width={'33%'}><PlayerNameLink playerDataBasic={allAmericanPlayerData.playerData} /></td>
                        <td width={'33%'}>{allAmericanPlayerData.positionAbbreviation}</td>					
                    </tr>
                </Fragment>
                )}                
                </tbody>
            </table>
            </center>
        </>
    );
}