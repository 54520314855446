import { PropsWithChildren } from "react";

interface IGameLinkProps { 
    id: string
}
export function GameLink(props: PropsWithChildren<IGameLinkProps>){
    const { id } = props;
    return (
        <>
            <a href={`${process.env.PUBLIC_URL}/boxScore.php?game_id=${id}`}>{props.children}</a>
        </>
    )
}