//import React from 'react';
//import logo from './logo.svg';
import './App.css';
import './404.css';
import { BrowserRouter, Link, Outlet, Route, Routes } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { ISessionData } from './controller_responses/SessionData';
import { Home } from './pages/Home';
import { LogIn } from './pages/LogIn';
import { LeagueHistory } from './pages/LeagueHistory';
import { ForgotPassword } from './pages/ForgotPassword';
import { Rules } from './pages/Rules';
import { TeamHome } from './pages/Teams/TeamHome';
import { Teams } from './pages/Teams';
import { Roster } from './pages/Teams/TeamRoster';
import { ITeamLayoutData } from './controller_responses/TeamLayoutData';
import { TeamLayout } from './pages/Teams/TeamLayout';
import { Analysis } from './pages/Teams/TeamAnaylsis';
import { Stats as TeamStats } from './pages/Teams/TeamStats';
import { Games } from './pages/Teams/TeamGames';
import { Awards } from './pages/Teams/TeamAwards';
import { AllAmericans } from './pages/AllAmericans';
import { Schedule } from './pages/Schedule';
import { BowlResults } from './pages/BowlResults';
import { Conference } from './pages/Conference';
import { Rankings } from './pages/Rankings';
import { Stats } from './pages/Stats';
import { StatsLink } from './helpers/SiteLinks';
import { UniformSummary } from './pages/UniformSummary';
import { CoachOptions } from './pages/CoachOptions';
import { MunozVotingResults } from './pages/MunozVotingResults';
import { Workhorses } from './pages/Workhorses';
import { TopUnderclassmen } from './pages/TopUnderclassmen';
import { ConferenceHistory } from './pages/ConferenceHistory';
import { MunozWinners } from './pages/MunozWinners';
import { HartLeeDykesWinners } from './pages/HartLeeDykesWinners';
import { HistoricalStandings } from './pages/HistoricalStandings';
import { StatisticsRecordBook } from './pages/StatisticsRecordBook';
import { CoachTendencies } from './pages/CoachTendencies';
import { TeamPrestige } from './pages/TeamPrestige';
import { ScheduleChecker } from './pages/ScheduleChecker';
import { RostersChecker } from './pages/RostersChecker';

function App() {
  const [sessionData, setSessionData] = useState<ISessionData>();
  const [sessionDataCnt, setSessionDataCnt] = useState<number>(0);

  const [teamLayoutData, setTeamLayoutData] = useState<ITeamLayoutData>();

  const sessionUpdateCallback = useCallback(()=>{
    setSessionDataCnt(sessionDataCnt+1);
  },[sessionDataCnt, setSessionDataCnt]);

  useEffect(()=>{
    fetch(`${process.env.PUBLIC_URL}/SessionData`, {credentials: 'include'}) 
            .then(response=>{return response.json()})
            .then((data: ISessionData)=> setSessionData(data) );
  }, [sessionDataCnt]);

  const teamLayoutDataCallback = useCallback((teamLayoutData: ITeamLayoutData)=>{
    setTeamLayoutData(teamLayoutData);
  },[setTeamLayoutData]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout sessionData={sessionData}/>}>     
          
          {/* Main Menu Pages and their subpages */}      
          <Route index element={<Home />} />
          <Route path='/Home' element={<Home />} />
          <Route path='/index.php' element={<Home />} />
          <Route path='/LogIn' element={<LogIn sessionData={sessionData} sessionUpdateCallback={sessionUpdateCallback} />} />
          <Route path='/Schedule' element={<Schedule />} />
          <Route path='/Rankings' element={<Rankings />} />            
          <Route path='/Teams' element={<Teams /> } />
            <Route path='/Teams/:TeamIdentifier' element={<TeamLayout teamLayoutDataCallback={teamLayoutDataCallback}/> }>
              <Route index element={<TeamHome teamLayoutData={teamLayoutData}/>} />
              <Route path="Home" element={<TeamHome teamLayoutData={teamLayoutData}/>} />
              <Route path="Analysis" element={<Analysis teamLayoutData={teamLayoutData}/>}>
                <Route path=":PositionSort" element={<Analysis teamLayoutData={teamLayoutData}/>} />
              </Route>
              <Route path="Roster" element={<Roster teamLayoutData={teamLayoutData}/>} />                
              <Route path="Stats" element={<TeamStats teamLayoutData={teamLayoutData}/>}>
                <Route path=":Season" element={<TeamStats teamLayoutData={teamLayoutData}/>} />
              </Route>
              <Route path="Games" element={<Games teamLayoutData={teamLayoutData}/>}>
                <Route path=":Season" element={<Games teamLayoutData={teamLayoutData}/>} />
              </Route>
              <Route path="Awards" element={<Awards />} />
            </Route>
          <Route path='/Rules' element={<Rules />} />
          <Route path='/Stats' element={<Stats />}>
            <Route path=":Season" element={<Stats />} />
          </Route>
          <Route path="/LeagueHistory" element={<LeagueHistory /> } />
          <Route path="/CoachOptions" element={<CoachOptions/>} />

          {/*Miscellaneous Page - Guests or Logged In Users*/}
          <Route path="/AllAmericans" element={<AllAmericans />}>
            <Route path=":Season" element={<AllAmericans />} />
          </Route>
          <Route path="/BowlResults" element={<BowlResults />} />
          <Route path="/CoachTendencies" element={<CoachTendencies />} />
          <Route path="/Conferences" element={<Conference />}>
            <Route path=":ConferenceIdentifier" element={<Conference />} />
          </Route>
          <Route path="/ConferenceHistory" element={<ConferenceHistory />}>
            <Route path=":Season" element={<ConferenceHistory />} />
          </Route>
          <Route path="/ForgotPassword" element={<ForgotPassword /> } />
          <Route path="/HartLeeDykesWinners" element={<HartLeeDykesWinners /> } />
          <Route path='/HistoricalRankings' element={<Rankings isHistorical />} >
            <Route path=':Season' element={<Rankings isHistorical />}>
              <Route path=':Week' element={<Rankings isHistorical />} />
            </Route>
          </Route>
          <Route path='/HistoricalStandings' element={<HistoricalStandings />} >
            <Route path=':Season' element={<HistoricalStandings  />} />
          </Route>
          <Route path="/MunozWinners" element={<MunozWinners /> } />
          <Route path="/RostersChecker" element={<RostersChecker /> } />
          <Route path="/ScheduleChecker" element={<ScheduleChecker /> } />
          <Route path="/StatisticsRecordBook" element={<StatisticsRecordBook /> } />
          <Route path="/TeamPrestige" element={<TeamPrestige /> } />
          <Route path="/TopUnderclassmen" element={<TopUnderclassmen />} />
          <Route path="/UniformSummary" element={<UniformSummary /> } />
          
          {/* Miscellaneous Pages - Commissioners */}
          <Route path="/MunozVotingResults" element={<MunozVotingResults />} />
          <Route path="/Workhorses" element={<Workhorses />} />


          {/* Fallback/Error/404 page */}
          <Route path="*" element={<PageDoesNotExist />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function PageDoesNotExist() {
  return (
    <div className="ErrorBox">
      <h3>Whoops, we can't find the webpage you specified!</h3>
      <img alt="Tecmo Super Bowl injury" title="We can't find the webpage" src={`${process.env.PUBLIC_URL}/TecmoSuperBowlImages/miscellaneous/SaintsInjury.png`} />
      <p>The page you were trying to reach doesn't exist. Feel free to check out some of these existing pages:</p>
      <MainMenuList shouldHideLoginItem />
    </div>
  );
}

interface ILayoutProps {
  sessionData: ISessionData | undefined;
}

function Layout(props: ILayoutProps){

  const { sessionData } = props;

  const isCurrentUserLoggedIn = sessionData && !sessionData.isUserAGuest;
  const isCurrentUserAGuest = !isCurrentUserLoggedIn;

  return (
    <div>
      <div className="content">
        <div className="pageBanner"><a href="/"><img src="/tcsbanner.gif" alt="SITETITLE Banner" title="SITETITLE" /></a></div>
        <div className="navigationMenu">
          <MainMenuList isCurrentUserAGuest={isCurrentUserAGuest} userName={sessionData?.userName} />         
	      </div>	
        <Outlet />
		  </div>      
	    <div id="DebugConsole"></div>
    </div>
  );
}

interface IMainMenuListProps {
  isCurrentUserAGuest?: boolean,
  userName?: string,
  shouldHideLoginItem?: boolean
}

function MainMenuList(props: IMainMenuListProps){
  const { isCurrentUserAGuest, shouldHideLoginItem, userName } = props;

  return (
    <ul>
        <li><Link to="/">Home</Link></li>
        {(shouldHideLoginItem) ? null :
          (isCurrentUserAGuest) ?               
            <>
            <li><Link to="/LogIn">Log In</Link></li>              
            </>              
              :
            <>
              <li><Link to="/CoachOptions">Welcome, {userName}</Link></li>
            <li><a href="/logout.php">Log Out</a></li>
            </>
        }
        <li><Link to='/Rankings'>Rankings</Link></li>
        <li><Link to="/Schedule">Schedule</Link></li>
        <li><Link to="/Teams">Teams</Link></li>
        <li><Link to="/Rules">Rules</Link></li>
        <li><StatsLink>Stats</StatsLink></li>
        <li><Link to="/LeagueHistory">League History</Link></li>
        <li><a href="http://www.tecmo.us/arena/viewforum.php?f=4">Forum</a></li>
        <li><Link to="/CoachOptions">Coach Options</Link></li>
      </ul>
  );
}

export default App;
