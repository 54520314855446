import { useParams } from "react-router-dom";
import { ITeamLayoutChildProps, SeasonsListLinks } from "./TeamLayout";
import { Fragment, useEffect, useState } from "react";
import { GameResultInitial, ITeamGamesData, ITeamGamesSeasonGamesData } from "../../controller_responses/TeamGamesData";
import { ITeamLayoutData } from "../../controller_responses/TeamLayoutData";

interface IGamesProps extends ITeamLayoutChildProps {}
export function Games(props: IGamesProps){
    const [teamGamesData, setTeamGamesData] = useState<ITeamGamesData>();

    const { teamLayoutData } = props;

    const { TeamIdentifier, Season } = useParams();

    useEffect(()=>{
        if (!TeamIdentifier) return;

        const teamGamesDataRequestBody = new URLSearchParams();
        teamGamesDataRequestBody.append('teamIdentifier', TeamIdentifier);
        teamGamesDataRequestBody.append('season', Season??"");
        fetch(`${process.env.PUBLIC_URL}/TeamGamesData`, { 
            body: teamGamesDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: ITeamGamesData)=> setTeamGamesData(data));
    },[TeamIdentifier, Season, setTeamGamesData]);

    const seasonDisplayText = (Number(Season) > 0) ? "Season " + Season : "All-Time";

    return (
        <>
            <center>
                <SeasonsListLinks seasonsList={teamGamesData?.seasonsList} teamLayoutData={teamLayoutData} title="Game Log" />
                <br/>
                <center>
                    <table width={600} border={0} style={{background: teamLayoutData?.secondaryColor, textAlign: 'center'}}>
	                    <tbody>
                            <tr style={{background: teamLayoutData?.primaryColor, color: teamLayoutData?.textColor}}>
                                <th>Week</th><th>{seasonDisplayText} Results</th><th>Score</th>
                            </tr>
                            {teamGamesData?.seasonGamesList?.map((seasonGamesListData)=>
                            <Fragment key={seasonGamesListData.season}>
                                <SeasonGamesRows teamLayoutData={teamLayoutData} seasonGamesListData={seasonGamesListData} />
                            </Fragment>
                            )}
                        </tbody>
                    </table>
                </center>                
            </center>
        </>
    );
}

interface ISeasonGamesRowsProps {
    teamLayoutData: ITeamLayoutData | undefined
    seasonGamesListData: ITeamGamesSeasonGamesData | undefined    
}
function SeasonGamesRows(props: ISeasonGamesRowsProps){
    const { teamLayoutData, seasonGamesListData} = props;

    return (
        <>
            <tr style={{background: teamLayoutData?.secondaryColor}}></tr>            
            {seasonGamesListData?.resultsList.map((gameResultData, index)=>
            <Fragment key={gameResultData.id}>
                <tr style={{background: (index % 2) ? '#eee' : '#fff' }}>
                    <td><b>{gameResultData.season} - {gameResultData.week}</b></td>
                    <td><b><a href={`${process.env.PUBLIC_URL}/boxScore.php?game_id=${gameResultData.id}`}>{gameResultData.ownerNickname} {gameResultData.locationIndicator} {gameResultData.opponentTeamInitials} ({gameResultData.opponentOwnerNickname})</a></b></td>
                    <td><b><ResultText gameResult={gameResultData.gameResult} /> {gameResultData.score} - {gameResultData.opponentScore}</b></td>
                </tr>                
            </Fragment>
            )}
        </>
    );
}

interface IResultTextProps {
    gameResult: GameResultInitial
}
function ResultText(props: IResultTextProps){
    const { gameResult } = props;

    let textColor: string = 'gold';

    if (gameResult === GameResultInitial.W){ textColor = 'green'; }
    else if (gameResult === GameResultInitial.L) { textColor = 'red'; }
    else if (gameResult === GameResultInitial.T) { textColor = 'gold'; }

    return (    
        <>            
            <span style={{color: textColor}}>{gameResult}</span>
        </>
    );
}