
interface ICoachLinkProps {
    id: string | undefined,
    nickname: string | undefined
    size: "Large" | "Small"
}
export function CoachLink(props: ICoachLinkProps){
    const { id, nickname, size } = props;

    const displayName = (nickname) ? nickname : "Vacant";

    if (!id){
        return <>{displayName}</>
    }

    let className = "";
    if (size === "Large") className="coachLinkLarge";
    if (size === "Small") className="coachLinkSmall";

    return (
        <>
            <a className={className} href={`${process.env.PUBLIC_URL}/coach.php?coach=${id}`}>{displayName}</a>
        </>
    );
}